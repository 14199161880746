import type {Branded} from '@/model/common/types';
import type {computed} from 'mobx';
import {autorun, observable, runInAction} from 'mobx';
import {useLocalObservable} from 'mobx-react-lite';
import {useEffect} from 'react';

export function useAutorunEffect(fn: () => void, nonObservableDeps: any[] = []) {
  return useEffect(() => {
    return autorun(fn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, nonObservableDeps);
}

export type ValueObservable<V> = {
  value: V;
};

export function useLocalValueObservableFromProp<V, T extends ValueObservable<V>>(config: T) {
  const valueObservable = useLocalObservable(() => {
    return config;
  });

  useEffect(() => {
    runInAction(() => {
      valueObservable.value = config.value;
    });
  }, [valueObservable, config.value]);

  return valueObservable;
}

export type MobxComputed<T> = ReturnType<typeof computed<T>>;

// Define a type that mimics Preact's Signal behavior but using MobX
export type MobXObservableOldSignal<T> = {
  value: T;
  peek: () => T;
};

type IdKey = Branded<string, 'IdKey'>;

export function createObservableOldSignal<T>(initialValue: T): MobXObservableOldSignal<T> {
  const obsObject = observable({
    _value: initialValue,
    get value(): T {
      return this._value;
    },
    set value(val: T) {
      runInAction(() => {
        this._value = val;
      });
    },
    peek(): T {
      return this._value;
    },
  });

  return obsObject;
}
