import StringUtils from '@/StringUtils';
import fastDeepEqual from 'fast-deep-equal';

const isObject = (o: any) => {
  return o != null && typeof o === 'object';
};
const isEmptyObject = (o: any) => {
  return isObject(o) && Object.keys(o).length === 0;
};

export default class ObjectUtils {
  static EmptyObject = Object.freeze({});

  static IsDate(obj: any) {
    return obj instanceof Date;
  }

  static IsEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  static IsPlainObject(obj: any): boolean {
    if (obj === null || typeof obj !== 'object') {
      return false;
    }
    return Object.getPrototypeOf(obj) === Object.prototype;
  }

  static TransformKeys(obj: object, fn: (key: string) => string) {
    if (!ObjectUtils.IsPlainObject(obj)) {
      return obj;
    }

    const keys = Object.keys(obj);
    for (const key of keys) {
      const newKey = fn(key);
      if (newKey !== key) {
        obj[newKey] = obj[key];
        delete obj[key];
      }

      if (Array.isArray(obj[newKey])) {
        obj[newKey] = obj[newKey].map((item: any) => {
          return ObjectUtils.TransformKeys(item, fn);
        });
      } else {
        obj[newKey] = ObjectUtils.TransformKeys(obj[newKey], fn);
      }
    }

    return obj;
  }

  static TransformKeysCamelCaseToSnakeCase(obj: object) {
    return ObjectUtils.TransformKeys(obj, StringUtils.CamelCaseToSnakeCase);
  }

  static TransformKeysSnakeCaseToCamelCase(obj: object) {
    const transformed = ObjectUtils.TransformKeys(obj, StringUtils.SnakeCaseToCamelCase);
    return transformed;
  }

  static GroupByKey<K extends string, T extends Record<K, any>>(array: T[], key: K = 'id' as K) {
    return array.reduce(
      (acc, item) => {
        acc[item[key]] = item;
        return acc;
      },
      {} as Record<T[K], T>,
    );
  }

  static MapValues<T extends Record<string, any>, U>(
    obj: T,
    fn: <K extends keyof T>(value: T[K], key: K) => U,
  ): {[K in keyof T]: U} {
    return Object.entries(obj).reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]: fn(value, key as keyof T),
        };
      },
      {} as {[K in keyof T]: U},
    );
  }

  static DeepClone<T>(obj: T): T {
    return structuredClone(obj);
  }

  static DeepEqual(a: any, b: any) {
    return fastDeepEqual(a, b);
  }

  static PartialDeepEqual<T extends object>(a: T, b: Partial<T>) {
    const aPartial = {};
    const bKeys = Object.keys(b);
    for (const bKey of bKeys) {
      aPartial[bKey] = a[bKey];
    }
    return fastDeepEqual(aPartial, b);
  }

  // taken from https://github.com/mattphillips/deep-object-diff/blob/main/src/diff.js
  static Diff<T extends object>(a: any, b: any): Partial<T> | null {
    if (a === b) return {}; // equal return no diff

    if (!isObject(a) || !isObject(b)) return b; // return updated rhs

    if (ObjectUtils.IsDate(a) || ObjectUtils.IsDate(b)) {
      if (a.valueOf() == b.valueOf()) return {};
      return b;
    }

    const diff = Object.keys(b).reduce((acc, key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!a.hasOwnProperty(key)) {
        acc[key] = b[key]; // return added r key
        return acc;
      }

      const difference = ObjectUtils.Diff(a[key], b[key]);

      // If the difference is empty, and the lhs is an empty object or the rhs is not an empty object
      if (
        !difference ||
        (isEmptyObject(difference) &&
          !ObjectUtils.IsDate(difference) &&
          (isEmptyObject(a[key]) || !isEmptyObject(b[key])))
      )
        return acc; // return no diff

      acc[key] = difference; // return updated key
      return acc; // return updated key
    }, {});

    if (ObjectUtils.IsEmpty(diff)) {
      return null;
    }

    return diff;
  }

  static DeepMergeImmutable<T extends object[]>(
    ...objects: T
  ): Identity<UnionToIntersection<T[number]>> {
    const isObject = (obj: any) => {
      return obj && typeof obj === 'object';
    };

    return objects.reduce<Identity<UnionToIntersection<T[number]>>>(
      (prev, obj) => {
        Object.keys(obj).forEach((key) => {
          const pVal = prev[key];
          const oVal = obj[key];

          if (Array.isArray(pVal) && Array.isArray(oVal)) {
            prev[key] = pVal.concat(...oVal);
          } else if (isObject(pVal) && isObject(oVal)) {
            prev[key] = ObjectUtils.DeepMergeImmutable(pVal, oVal);
          } else {
            prev[key] = oVal;
          }
        });

        return prev;
      },
      {} as Identity<UnionToIntersection<T[number]>>,
    );
  }
}
