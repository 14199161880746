import {useEffect} from 'react';
import GlobalEventEmitter from '@/events/GlobalEventEmitter';
import type {GlobalEventEmitterEvents} from '@/events/GlobalEventEmitterEvents';

export default function useGlobalEventEmitterEvent<T extends GlobalEventEmitterEvents['type']>(
  eventName: T,
  handler: (
    data: Extract<GlobalEventEmitterEvents, {type: T}>['data'],
    clientContext?: {optimisticId: string},
  ) => void,
) {
  useEffect(() => {
    function listener(event: Event) {
      const {detail} = event as CustomEvent;
      handler(detail.data, detail.clientContext);
    }
    GlobalEventEmitter.on(eventName, listener);
    return () => {
      GlobalEventEmitter.off(eventName, listener);
    };
    // we only want this to run on mount and unmount since we're going to be passing a new function in every time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
