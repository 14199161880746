import type {Board, BoardIdObj} from '@/model/board/types';
import type {BoardItemId, ClientBoardItemWithOrder} from '@/model/board_item/types';
import BoardItemType from '@/model/board_item_type/types';
import type {ExtendedOrg, OrgIdObj} from '@/model/org/types';
import {type Org, type OrgId} from '@/model/org/types';
import type {User} from '@/model/user/types';
import ObjectUtils from '@/ObjectUtils';
import {EntityArraySignalsByIdStore, EntitySignalsByIdStore} from '@/state/stores';
import {createObservableOldSignal} from '@/utils/mobx';
import {useValtioSnapshot, valtioProxy, when} from '@/utils/valtio';
import {computed} from 'mobx';

export const currentUserProxy = valtioProxy<User | null | undefined>(undefined);
export const currentUserIsAdminProxy = valtioProxy<boolean | null | undefined>(undefined);
export function useCurrentUser() {
  const currentUser = useValtioSnapshot(currentUserProxy);
  return currentUser;
}

export const userOrgsSignal = createObservableOldSignal<ExtendedOrg[] | null | undefined>(
  undefined,
);

export const orgSignalsByIdStore = new EntitySignalsByIdStore<OrgIdObj, Org>();
export function useOrg(orgId: OrgId) {
  const org = orgSignalsByIdStore.getById({id: orgId})?.value as ExtendedOrg | undefined;
  return org;
}
export const boardSignalsByIdStore = new EntitySignalsByIdStore<BoardIdObj, Board>();
export const boardsSignalsByOrgIdStore = new EntityArraySignalsByIdStore<
  OrgIdObj,
  BoardIdObj,
  Board
>(boardSignalsByIdStore);

const nullGroup = {
  id: 'null',
  type: BoardItemType.Group,
};

export const emptyGroups = [nullGroup];
export const groupsSignal = createObservableOldSignal(emptyGroups as ClientBoardItemWithOrder[]);
export const groupsByGroupIdComputed = computed(() => {
  return ObjectUtils.GroupByKey(groupsSignal.value);
});

export const emptyItemsByGroupId = {
  ['null']: [],
};
export const itemsByGroupIdSignal =
  createObservableOldSignal<Record<BoardItemId | 'null', ClientBoardItemWithOrder[]>>(
    emptyItemsByGroupId,
  );

export const itemsByItemIdComputed = computed(() => {
  const itemsByItemId = Object.values(itemsByGroupIdSignal.value).reduce((acc, items) => {
    Object.assign(acc, ObjectUtils.GroupByKey(items));
    return acc;
  }, {});
  return itemsByItemId;
});

// eslint-disable-next-line livan/mobx-missing-observer
export async function GetCurrentUser() {
  await when((get) => {
    const value = get(currentUserProxy).value;
    return value !== undefined;
  });
  return currentUserProxy.value as User | null;
}
