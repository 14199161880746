import type {Branded, Identifiable} from '@/model/common/types';
import assert from '@/utils/assert';
import {observable, action, runInAction, makeObservable} from 'mobx';

// Define a type that mimics Preact's Signal behavior but using MobX
export type MobXObservable<T> = {
  value: T;
  peek: () => T;
};

type IdKey = Branded<string, 'IdKey'>;

// Helper function to create a MobX observable that mimics Preact's Signal
function createObservable<T>(initialValue: T): MobXObservable<T> {
  const obsObject = observable({
    _value: initialValue,
    get value(): T {
      return this._value;
    },
    set value(val: T) {
      runInAction(() => {
        this._value = val;
      });
    },
    peek(): T {
      return this._value;
    },
  });

  return obsObject;
}

abstract class BaseEntitiesByIdStore<IdObj extends Record<string, string | number>> {
  protected constructIdKey(idObj: Record<string, any>): IdKey {
    const entries = Object.entries(idObj);
    if (entries.length === 1) {
      // optimization
      const idKey = entries[0][1].toString() as IdKey;
      return idKey;
    }
    const idKey = entries
      .sort((a, b) => {
        return a[0].localeCompare(b[0]);
      })
      .map(([key, value]) => {
        return `${key}:${value}`;
      })
      .join('|') as IdKey;
    return idKey;
  }
}

export class EntitySignalsByIdStore<
  IdObj extends Record<string, string | number>,
  Entity extends Identifiable<IdObj>,
> extends BaseEntitiesByIdStore<IdObj> {
  private entitiesByIdKey: Record<IdKey, MobXObservable<Entity | null | undefined>> = {};

  constructor() {
    super();
    // No need to use makeObservable for now since we're directly working with observables
    // This avoids the linter errors
  }

  private set(idKey: IdKey, entity: Entity | null | undefined) {
    if (!this.entitiesByIdKey[idKey]) {
      this.entitiesByIdKey[idKey] = createObservable(entity);
    } else {
      this.entitiesByIdKey[idKey].value = entity;
    }
    return this.get(idKey);
  }

  private get(idKey: IdKey) {
    return this.entitiesByIdKey[idKey];
  }

  getById(idObj: IdObj): MobXObservable<Entity | null | undefined> {
    const idKey = this.constructIdKey(idObj);
    if (!this.get(idKey)) {
      this.entitiesByIdKey[idKey] = createObservable(undefined);
    }
    return this.get(idKey);
  }

  create(idObj: IdObj, entity: Entity): MobXObservable<Entity> {
    const idKey = this.constructIdKey(idObj);
    const entityObservable = this.set(idKey, entity) as MobXObservable<Entity>;
    return entityObservable;
  }

  update(idObj: IdObj, partialEntity: Partial<Entity>): MobXObservable<Entity> | null {
    const idKey = this.constructIdKey(idObj);
    const entityObservable = this.get(idKey);
    const entity = entityObservable.peek();
    assert(entity, `entity is required`);
    const entityToUpdate = {
      ...entity,
      ...partialEntity,
    };
    const updatedEntityObservable = this.set(idKey, entityToUpdate) as MobXObservable<Entity>;
    return updatedEntityObservable;
  }

  delete(idObj: IdObj): void {
    const idKey = this.constructIdKey(idObj);
    this.set(idKey, null);
  }
}

export class EntityArraySignalsByIdStore<
  EntityAIdObj extends Record<string, string | number>,
  EntityBIdObj extends Record<string, string | number>,
  EntityB extends Identifiable<EntityBIdObj>,
> extends BaseEntitiesByIdStore<EntityAIdObj> {
  private entitiesByIdKey: Record<
    IdKey,
    MobXObservable<MobXObservable<EntityB>[] | null | undefined>
  > = {};

  entitySignalsByIdStore: EntitySignalsByIdStore<EntityBIdObj, EntityB> | null = null;

  constructor(entitySignalsByIdStore?: EntitySignalsByIdStore<EntityBIdObj, EntityB>) {
    super();
    if (entitySignalsByIdStore) {
      this.entitySignalsByIdStore = entitySignalsByIdStore;
    }
  }

  private set(idKey: IdKey, entity: MobXObservable<EntityB>[] | null | undefined) {
    if (!this.entitiesByIdKey[idKey]) {
      this.entitiesByIdKey[idKey] = createObservable(entity);
    } else {
      this.entitiesByIdKey[idKey].value = entity;
    }
    return this.entitiesByIdKey[idKey];
  }

  getById(idObj: EntityAIdObj): MobXObservable<MobXObservable<EntityB>[] | null | undefined> {
    const idKey = this.constructIdKey(idObj);
    if (!this.entitiesByIdKey[idKey]) {
      this.entitiesByIdKey[idKey] = createObservable(undefined);
    }
    return this.entitiesByIdKey[idKey];
  }

  create(idObj: EntityAIdObj, entities: EntityB[]): MobXObservable<MobXObservable<EntityB>[]> {
    const idKey = this.constructIdKey(idObj);
    const entityObservables = entities.map((entity) => {
      if (this.entitySignalsByIdStore) {
        // @ts-expect-error this won't work when we get to compound keys, need to construct key dynamically
        return this.entitySignalsByIdStore.create({id: entity.id}, entity);
      } else {
        return createObservable(entity);
      }
    });
    return this.set(idKey, entityObservables) as MobXObservable<MobXObservable<EntityB>[]>;
  }

  createItem(idObj: EntityAIdObj, entity: EntityB): MobXObservable<EntityB> {
    const idKey = this.constructIdKey(idObj);
    let entityObservable;
    if (this.entitySignalsByIdStore) {
      // @ts-expect-error this won't work when we get to compound keys, need to construct key dynamically
      entityObservable = this.entitySignalsByIdStore.create({id: entity.id}, entity);
    } else {
      entityObservable = createObservable(entity);
    }

    const existing = this.entitiesByIdKey[idKey]?.value;
    if (existing) {
      this.set(idKey, [...existing, entityObservable]);
    } else {
      this.set(idKey, [entityObservable]);
    }
    return entityObservable;
  }

  updateItem(
    idObj: EntityAIdObj,
    partialEntity: EntityBIdObj & Partial<Omit<EntityB, keyof EntityBIdObj>>,
  ): MobXObservable<EntityB> {
    const idKey = this.constructIdKey(idObj);
    let entityObservable: MobXObservable<EntityB>;
    if (this.entitySignalsByIdStore) {
      // @ts-expect-error this won't work when we get to compound keys, need to construct key dynamically
      entityObservable = this.entitySignalsByIdStore.update({id: partialEntity.id}, partialEntity);
    } else {
      const entityObservables = this.entitiesByIdKey[idKey]?.value;
      assert(entityObservables, `entityObservables is required`);
      entityObservable = entityObservables.find((entityObservable) => {
        return entityObservable.peek().id === partialEntity.id;
      })!;
      assert(entityObservable, `entityObservable is required`);
      entityObservable.value = {
        ...entityObservable.value,
        ...partialEntity,
      };
    }

    return entityObservable;
  }

  delete(idObj: EntityAIdObj): void {
    const idKey = this.constructIdKey(idObj);
    this.set(idKey, null);
  }

  deleteItem(idObjA: EntityAIdObj, idObjB: EntityBIdObj): void {
    const idKey = this.constructIdKey(idObjA);

    const filtered = this.entitiesByIdKey[idKey].value!.filter((entityObservable) => {
      const entity = entityObservable.peek();
      return !Object.entries(idObjB).every(([key, value]) => {
        return entity[key] === value;
      });
    });
    this.set(idKey, filtered);
  }
}
