import {useValtioSnapshot, valtioProxy} from '@/utils/valtio';
import {useRevalidator} from 'react-router';
import React from 'react';
import {useEffect, type FC} from 'react';
import useGlobalEventEmitterEvent from '@/events/useGlobalEventEmitterEvent';
import {observer} from 'mobx-react-lite';

export const shouldRevalidateRootOnNextRevalidate = {value: false};
export const shouldRevalidateRootProxy = valtioProxy<boolean>(false);
export default function revalidateRoot() {
  shouldRevalidateRootProxy.value = true;
}

export const RootRevalidator: FC = observer(function RootRevalidator() {
  const revalidator = useRevalidator();
  const shouldRevalidateRoot = useValtioSnapshot(shouldRevalidateRootProxy);

  useGlobalEventEmitterEvent('websocket-reconnected', () => {
    shouldRevalidateRootOnNextRevalidate.value = true;
    revalidator.revalidate();
  });

  // HACK
  useEffect(() => {
    if (shouldRevalidateRoot) {
      shouldRevalidateRootOnNextRevalidate.value = true;
      revalidator.revalidate();
      shouldRevalidateRootProxy.value = false;
    }
  }, [shouldRevalidateRoot, revalidator]);

  return null;
});
