import clsx from 'clsx';
import type {BaseComponentProps} from '@/components/types';
import type {FC} from 'react';
import {CheckCircle, AlertCircle, AlertTriangle, EyeOff, Search, XCircle} from 'lucide-react';
import Button from '@/components/core/Button';
import {useNavigate} from 'react-router';
import {observer} from 'mobx-react-lite';

const emptyStateMetaByType = {
  Success: {
    Icon: CheckCircle,
    title: 'Success',
    description: 'The operation was successful',
  },
  Forbidden: {
    Icon: EyeOff,
    title: 'Forbidden',
    description: 'You do not have permissions to view this page.',
  },
  BadRequest: {
    Icon: AlertTriangle,
    title: 'Error',
    description: 'The request was invalid.',
  },
  NoResults: {
    Icon: Search,
    title: 'No results',
    description: 'There are no results for this search.',
  },
  PageNotFound: {
    Icon: XCircle,
    title: 'Not found',
    description: 'This page does not exist.',
  },
  GenericError: {
    Icon: AlertCircle,
    title: 'Error',
    description: 'An unexpected error occurred loading this page.',
  },
};

type Props = BaseComponentProps & {
  type: keyof typeof emptyStateMetaByType;
  title?: string;
  description?: string;
  Icon?: React.ElementType;
} & XOR<
    {
      buttonText?: string;
    } & XOR<
      {
        buttonTo?: string;
      },
      {
        buttonOnClick?: () => void;
      }
    >,
    {
      buttons: {
        to?: string;
        text: string;
        onClick?: () => void;
      }[];
    }
  >;

const EmptyState: FC<Props> = observer(function EmptyState(props) {
  const {className, type, buttonTo, buttonText, buttons = [], buttonOnClick} = props;
  const emptyStateMeta = emptyStateMetaByType[type] || emptyStateMetaByType.GenericError;

  if (!buttons.length || buttonTo || buttonText) {
    buttons.unshift({
      to: buttonTo || '..',
      text: buttonText || 'Go back',
      onClick: buttonOnClick,
    });
  }

  const {
    title = emptyStateMeta.title,
    description = emptyStateMeta.description,
    Icon = emptyStateMeta.Icon,
  } = props;

  const navigate = useNavigate();
  const goBack = () => {
    return navigate(-1);
  };

  return (
    <div
      className={clsx(
        'EmptyState',
        className,
        'flex flex-col justify-center items-center w-full gap-4 py-6 sm:py-10 px-4 sm:px-6',
      )}
    >
      <Icon size={128} />
      <h2>{title}</h2>
      <div className="max-w-96 text-center">{description}</div>
      <div className="flex gap-2">
        {buttons.map(({to, text, onClick}, index) => {
          return (
            // @ts-expect-error not sure how to make this happy
            <Button
              key={index}
              to={to}
              onClick={onClick}
              role="button"
            >
              {text}
            </Button>
          );
        })}
      </div>
    </div>
  );
});

export default EmptyState;
