import '@/components/core/LivanLink.css';
import {type ReactNode, type ComponentProps, type MouseEventHandler, forwardRef} from 'react';
import clsx from 'clsx';
import {Link} from 'react-router';
import type {To} from 'react-router';

import React from 'react';
import {observer} from 'mobx-react-lite';

type LinkType = 'button' | 'button-outline' | 'link' | 'link-light' | 'unstyled';

export type LivanLinkProps = Omit<ComponentProps<typeof Link>, 'to'> & {
  className?: string;
  type?: LinkType;
  content?: ReactNode;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  active?: boolean;
  to?: To;
};

const LivanLink = observer(
  forwardRef(function LivanLink(props: LivanLinkProps, ref) {
    const {
      className,
      children,
      content,
      type = 'link',
      disabled,
      active,
      onClick,
      prefetch = 'viewport',
      to,
      ...linkProps
    } = props;

    if (typeof to === 'string' && !to.startsWith('/') && !to.startsWith('..')) {
      // we're navigating elsewhere, append target and whatnot
      linkProps.target = '_blank';
      if (!linkProps.rel) {
        linkProps.rel = 'noreferrer noopener';
      }
    }

    const Component = to ? Link : 'a';

    return (
      <Component
        tabIndex={type !== 'button' ? -1 : undefined}
        {...linkProps}
        // @ts-expect-error not sure how to silence this
        ref={ref}
        className={clsx(
          className,
          'LivanLink font-normal',
          'focus:outline-2 focus:outline-offset-2',
          (type === 'button' || type === 'button-outline') &&
            'p-4 rounded-md px-3 py-2 font-medium',
          type === 'button-outline' && 'text-livan-black hover:bg-gray-600 hover:text-white border',
          type === 'button-outline' &&
            (active ? 'bg-livan-black text-white' : 'bg-white text-livan-black'),
          type === 'button-outline' &&
            (disabled ? 'border-gray-400' : 'border-livan-black hover:border-gray-600'),
          type === 'button' && (disabled ? 'bg-gray-400' : 'bg-livan-black hover:bg-gray-600'),
          type === 'button' && 'text-white',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          type === 'link' && 'underline  text-gray-800 hover:text-gray-600',
          type === 'link-light' && 'underline  text-white hover:text-gray-300',
        )}
        // @ts-expect-error - not sure how to make typescript happy here because `Component` won't be `Link` when `to` is not defined
        to={to}
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
        prefetch={prefetch}
      >
        {content || children}
      </Component>
    );
  }),
);

export default LivanLink;
