import navigate from '@/api/GlobalNavigator';
import {shouldRevalidateRootOnNextRevalidate} from '@/api/RootRevalidator';
import EmptyState from '@/components/core/EmptyState';
import LoadingIndicator from '@/components/core/LoadingIndicator';
import {currentUserIsAdminProxy, GetCurrentUser} from '@/context/UserContext';
import useGlobalEventEmitterEvent from '@/events/useGlobalEventEmitterEvent';
import type {User} from '@/model/user/types';
import {constructErrorMeta, displayClientError, type ErrorMeta} from '@/utils/errors';

import clsx from 'clsx';
import {observer, useLocalObservable} from 'mobx-react-lite';
import type {Dispatch, ReactNode, SetStateAction} from 'react';
import {Suspense, useEffect, useState} from 'react';
import type {ActionFunctionArgs, LoaderFunctionArgs} from 'react-router';
import {
  Await,
  Navigate,
  useActionData,
  useAsyncError,
  useLoaderData,
  useNavigation,
  useRevalidator,
} from 'react-router';

type LivanDataFunctionDataWithoutLoading<T> = XOR<
  {
    errors: ErrorMeta;
  },
  {
    result: T;
  }
>;

type LivanDeferredLoaderFunctionData<T> = {
  deferred: LivanDataFunctionDataWithoutLoading<T>;
};

export type LivanDataFunctionData<T> =
  | (LivanDataFunctionDataWithoutLoading<T> & {
      loading: false;
    })
  | {
      errors?: never;
      loading: true;
      result?: never;
    };

function handleDataFunctionError(error: Error) {
  const errorMeta = constructErrorMeta(error);
  if (errorMeta) {
    return {
      errors: errorMeta,
    };
  }
  throw error;
}

export function withDeferredLoaderErrorHandling<T1>(
  fn: (args: LoaderFunctionArgs, user: User | null) => Promise<T1>,
  options?: {
    ignoreUserFetch?: boolean; // only for the root route!!
    allowsUnauthenticated?: boolean;
    isAdminOnly?: boolean;
  },
) {
  const {ignoreUserFetch, allowsUnauthenticated, isAdminOnly} = options || {};
  const loaderWrapper = async function loaderWrapper(args: Parameters<typeof fn>[0]) {
    let user: User | null = null;
    if (!ignoreUserFetch) {
      user = await GetCurrentUser();
    }
    if ('serverLoader' in args && !allowsUnauthenticated && !user) {
      const {request} = args;
      const redirectTo = new URL(request.url).pathname;
      navigate(`/log-in?redirectTo=${redirectTo}`);
      return {
        __returnNull: true,
      };
    }

    if ('serverLoader' in args && isAdminOnly && !currentUserIsAdminProxy.value) {
      navigate(`/`);
      return {
        __returnNull: true,
      };
    }

    const resultPromise = fn(args, user);
    return {
      deferred: resultPromise
        .then(async (result) => {
          return {result};
        })
        .catch(handleDataFunctionError),
    };
  };
  return loaderWrapper;
}

export function withActionErrorHandling<T1>(fn: (args: ActionFunctionArgs) => Promise<T1>) {
  const actionWrapper = async function actionWrapper(
    args: Parameters<typeof fn>[0],
  ): Promise<LivanDataFunctionDataWithoutLoading<T1>> {
    try {
      const result = await fn(args);
      return {result};
    } catch (e) {
      return handleDataFunctionError(e as Error);
    }
  };
  return actionWrapper;
}

function useLoaderErrorStatus(errors: ErrorMeta | undefined) {
  useEffect(() => {
    displayClientError(errors, {
      fieldErrorMessage: 'Invalid parameters in URL',
    });
  }, [errors]);
}

function useActionErrorStatus(errors: ErrorMeta | undefined) {
  useEffect(() => {
    displayClientError(errors);
  }, [errors]);
}

const DeferredComponent = observer(function DeferredComponent(props: any) {
  const {Component, actionData} = props;
  const loaderData = useLocalObservable(() => {
    return props.loaderData;
  });
  useLoaderErrorStatus(loaderData?.errors);

  if (loaderData?.errors?.status != null) {
    if (loaderData.errors.status === 403) {
      return (
        <EmptyState
          type="Forbidden"
          description={loaderData.errors.message}
        />
      );
    } else if (loaderData?.errors.status === 400) {
      return (
        <EmptyState
          type="BadRequest"
          description={loaderData.errors.message}
        />
      );
    } else if (loaderData?.errors.status === 404) {
      return (
        <EmptyState
          type="PageNotFound"
          description={loaderData.errors.message}
        />
      );
    } else {
      return <EmptyState type="GenericError" />;
    }
  }

  return (
    <Component
      {...props}
      loaderData={loaderData}
      actionData={actionData}
    />
  );
});

// hack to get remix to play nicely with throwing redirects within deferred functions
const AwaitRedirect = observer(function AwaitRedirect(params) {
  const error = useAsyncError();
  if (error instanceof Response && error.status >= 300 && error.status < 400) {
    return <Navigate to={error.headers.get('Location')!} />;
  }
  throw error;
});

export function withLivanComponentWrapper<P, LoaderResult, ActionResult>({
  clientLoader,
  Component,
}: {
  clientLoader?: ReturnType<typeof withDeferredLoaderErrorHandling<LoaderResult>>;
  Component: (
    props: P & {
      loaderData: LivanDataFunctionDataWithoutLoading<LoaderResult>;
      actionData: LivanDataFunctionData<ActionResult>;
    },
  ) => ReactNode;
}) {
  const ObserverComponent = observer(Component);
  const LivanComponentWrapper = observer(function LivanComponentWrapper(props: P) {
    const deferredLoaderData = useLivanDeferredLoaderData<LoaderResult>();
    const actionData = useLivanActionData<ActionResult>();
    useActionErrorStatus(actionData?.errors);

    const loaderData = useLocalObservable(() => {
      return deferredLoaderData?.deferred;
    });

    if ((deferredLoaderData as any)?.__returnNull) {
      return null;
    }
    if (deferredLoaderData?.deferred instanceof Promise) {
      return (
        <Suspense fallback={<SuspenseFallback centered />}>
          <Await
            resolve={deferredLoaderData.deferred}
            errorElement={<AwaitRedirect />}
          >
            {({result, errors}) => {
              return (
                <DeferredComponent
                  Component={ObserverComponent}
                  loaderData={{result, errors}}
                  actionData={actionData}
                />
              );
            }}
          </Await>
        </Suspense>
      );
    }

    return (
      <ObserverComponent
        {...props}
        loaderData={loaderData}
        actionData={actionData}
      />
    );
  });

  // overwrite name for dev-tools so that all components don't appear as `LivanComponentWrapper` in react tree
  Object.defineProperty(LivanComponentWrapper, 'name', {
    writable: true,
    value: Component.name,
  });

  return LivanComponentWrapper;
}

function useLivanDeferredLoaderData<T>(): LivanDeferredLoaderFunctionData<T> {
  const loaderData = useLoaderData<LivanDeferredLoaderFunctionData<T>>() || {};
  return loaderData;
}

function useLivanLoaderData<T>(): LivanDataFunctionDataWithoutLoading<T> {
  const loaderData = useLoaderData<LivanDataFunctionDataWithoutLoading<T>>() || {};
  return loaderData;
}

function useLivanActionData<T>(): LivanDataFunctionData<T> {
  const navigation = useNavigation();
  const actionData = useActionData<LivanDataFunctionDataWithoutLoading<T>>();
  if (navigation.state === 'submitting') {
    return {
      loading: true,
    };
  }
  // @ts-expect-error not sure how to fix this
  return {
    ...actionData,
    loading: false,
  };
}

type SuspenseFallbackProps = {
  className?: string;
  centered?: boolean;
};
export const SuspenseFallback = observer(function SuspenseFallback(props: SuspenseFallbackProps) {
  const {className, centered} = props;
  return (
    <div className={clsx(centered && 'size-full flex items-center justify-center p-10', className)}>
      <LoadingIndicator
        color="black"
        size="md"
      />
    </div>
  );
});

export function withSuspense<P>(Component: React.ComponentType<P>) {
  return observer(function WrappedComponent(props: React.PropsWithChildren<P>) {
    return (
      <Suspense fallback={<SuspenseFallback />}>
        <Component {...props} />
      </Suspense>
    );
  });
}

export function constructTitleMeta(...subtitles: string[]) {
  return {
    title: [...subtitles, 'Livan'].filter(Boolean).join(' – '),
  };
}
